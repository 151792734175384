<template>
  <v-container class="pa-0">
    <div class="d-flex flex-column">
      <div class="d-flex">
        <div
          class="secondary rounded-lg mt-8"
          style="height: 24px; width: 8px"
        />

        <v-card
          class="py-3 py-md-6 px-4 px-md-8 mb-4"
          color="surface"
          width="100%"
          flat
          tile
        >
          <div
            class="d-flex flex-column flex-md-row align-md-center justify-space-between mb-6"
          >
            <div class="d-flex align-center mb-2 mb-md-0">
              <back-button class="mr-2" />

              <div class="text-h5 font-weight-bold flex-grow-1">
                <span class="saira"> Minhas Ligas </span>
              </div>
            </div>

            <v-btn
              class="rounded-l-0 rounded-r-lg"
              color="primary"
              outlined
              to="/leagues"
            >
              <v-icon left>mdi-account-multiple</v-icon> Todas as ligas
            </v-btn>
          </div>

          <GamesChips :disabled="loading" />

          <loader-content v-if="loading" />

          <section v-else>
            <v-data-table
              v-if="leagues.length > 0"
              class="transparent"
              :headers="headers"
              :items="leagues"
              :items-per-page="pagination.itemsPerPage"
              hide-default-footer
              disable-sort
              @dblclick:row="($event, { item }) => handleSelectLeague(item)"
            >
              <template v-slot:[`item.league`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar class="pointer mr-3" size="32" v-ripple rounded>
                    <v-img
                      :src="
                        item.image ||
                        require('@/assets/leagues/default-league-logo.svg')
                      "
                      contain
                      @click="handleSelectLeague(item)"
                    />
                  </v-avatar>

                  <span
                    class="text--body-4 primary--text pointer text-decoration-underline"
                    v-text="item.name"
                    v-ripple
                    @click="handleSelectLeague(item)"
                  />
                </div>
              </template>

              <template v-slot:[`item.team`]="{ item }">
                <v-tooltip bottom open-delay="150" close-delay="150">
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" v-on="on" v-bind="attrs">
                      <v-avatar
                        class="pointer mr-3"
                        size="32"
                        rounded
                        v-ripple
                        @click="handleSelectTeam(item)"
                      >
                        <v-img :src="item.myTeam.logo" contain />
                      </v-avatar>

                      <span
                        class="pointer text-decoration-underline text--body-4"
                        v-ripple
                        v-text="item.myTeam.name"
                        @click="handleSelectTeam(item)"
                      />
                    </div>
                  </template>
                  <template v-slot:default>
                    <div class="d-flex flex-column justify-center align-center">
                      <div
                        v-for="(player, index) of item.myTeam.team_players"
                        :key="index"
                        class="d-flex align-center"
                      >
                        <v-icon
                          x-small
                          v-text="
                            item.myTeam.userId === player.user.id
                              ? 'mdi-shield-account'
                              : 'mdi-account'
                          "
                        />
                        <span
                          class="text--body-4 ms-1"
                          v-text="player.user.nickname"
                        />
                      </div>
                    </div>
                  </template>
                </v-tooltip>
              </template>

              <template v-slot:[`item.game`]="{ item }">
                <div class="d-flex align-center">
                  <v-avatar class="mr-3" size="32" rounded @dblclick.stop>
                    <v-img :src="item.game.thumb" contain />
                  </v-avatar>

                  <span
                    class="text--body-4"
                    v-text="item.game.name"
                    @dblclick.stop
                  />
                </div>
              </template>

              <template v-slot:[`item.enrollmentsDates`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="text--body-4"
                    v-text="
                      formatDate(item.enrollmentsInitDate, 'DD/MM/YY') + ' à'
                    "
                    @dblclick.stop
                  />
                  <span
                    class="text--body-4"
                    v-text="formatDate(item.enrollmentsFinalDate, 'DD/MM/YY')"
                    @dblclick.stop
                  />
                </div>
              </template>

              <template v-slot:[`item.tournamentDates`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="text--body-4"
                    v-text="
                      formatDate(item.tournamentInitDate, 'DD/MM/YY') + ' à'
                    "
                    @dblclick.stop
                  />
                  <span
                    class="text--body-4"
                    v-text="formatDate(item.tournamentFinalDate, 'DD/MM/YY')"
                    @dblclick.stop
                  />
                </div>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <div class="d-flex flex-column align-start">
                  <div
                    class="d-flex px-1 mt-1"
                    @dblclick.stop
                    :style="{
                      border: `1px solid ${item.status.color}`,
                      backgroundColor: item.status.filled && item.status.color,
                      borderRadius: '4px',
                    }"
                  >
                    <span
                      class="text--body-4"
                      v-text="item.status.text"
                      @dblclick.stop
                    />
                  </div>
                  <div class="d-flex" @dblclick.stop>
                    <v-icon x-small v-text="'mdi-calendar'" />
                    <span
                      class="text--body-4 font-weight-medium ms-1"
                      v-text="item.status.label"
                    />
                  </div>
                  <span
                    class="text--body-4"
                    v-text="formatDate(item.status.date, 'DD/MM/YY HH:mm')"
                    @dblclick.stop
                  />
                </div>
              </template>
            </v-data-table>

            <!-- empty -->
            <div
              v-else
              class="d-flex flex-column align-center justify-center py-8"
            >
              <v-icon x-large v-text="'mdi-emoticon-sad-outline'" />

              <span
                class="text--body-2"
                v-text="'Você não participou de nenhuma liga até o momento.'"
              />

              <PrimaryButton
                v-if="this.$route.name !== 'Leagues'"
                class="mt-6"
                :icon="'mdi-emoticon-outline'"
                text="Participar"
                @click="handleParticipate"
              />
            </div>
          </section>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { getMyLeagues } from "@/services/leagues";
import { formatDate } from "@/utils";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import GamesChips from "@/components/games/GamesChips";
import BackButton from "@/components/buttons/BackButton";
import moment from "moment";

export default {
  name: "MyLeagues",
  components: { BackButton, GamesChips, PrimaryButton },

  props: {
    hideHeader: {
      type: Boolean,
    },
    hideGames: {
      type: Boolean,
    },
  },

  data() {
    return {
      loading: true,
      pagination: {
        page: 0,
        pageCount: 1,
        itemsPerPage: 20,
        total: 0,
      },
      headers: [
        {
          text: "Liga",
          value: "league",
        },
        {
          text: "Equipe",
          value: "team",
        },
        {
          text: "Jogo",
          value: "game",
        },
        {
          text: "Período de Inscrições",
          value: "enrollmentsDates",
          align: "center",
        },
        {
          text: "Período de Jogos",
          value: "tournamentDates",
          align: "center",
        },
        {
          text: "Status",
          value: "status",
        },
      ],
      leagues: [],
    };
  },

  computed: {
    ...mapState(["localUser", "modalities"]),

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  beforeMount() {
    this.getData();
  },

  watch: {
    [`$route.query`](value) {
      this.handleQuery(value);
    },
  },

  methods: {
    formatDate,

    async getData(filters = {}) {
      try {
        this.loading = true;

        const payload = { ...filters };

        await getMyLeagues(payload).then((data) => {
          this.leagues = data.map((e) => ({
            ...e,
            myTeam:
              e.teams.find((team) =>
                team.team_players.some((e) => e.user.id === this.localUser.id)
              ) || {},
            status: this.getStatus(e),
          }));
        });
      } catch (err) {
        // err
      } finally {
        this.loading = false;
      }
    },

    handleQuery(value) {
      this.loading = true;
      this.pagination.page = 0;
      this.getData(value);
    },

    handleSelectTeam(item) {
      this.$router.push({ path: `/team-details/${item.myTeam.id}` });
    },

    handleSelectLeague(item) {
      this.$router.push({ path: `/league-details/${item.id}` });
    },

    handleParticipate() {
      this.$router.push({ path: "/leagues" });
    },

    getStatusValue(league) {
      const now = moment();
      const enrollmentsInitDate =
        league.enrollmentsInitDate && moment(league.enrollmentsInitDate);
      const enrollmentsFinalDate =
        league.enrollmentsFinalDate && moment(league.enrollmentsFinalDate);
      const tournamentInitDate =
        league.tournamentInitDate && moment(league.tournamentInitDate);
      const tournamentFinalDate =
        league.tournamentFinalDate && moment(league.tournamentFinalDate);

      // Inscrições abertas
      if (now.isBetween(enrollmentsInitDate, enrollmentsFinalDate)) return 0;

      // Em andamento
      if (now.isBetween(tournamentInitDate, tournamentFinalDate)) return 1;

      // Em Breve
      if (now.isBefore(enrollmentsInitDate)) return 2;

      // Finalizadas
      if (now.isAfter(tournamentFinalDate)) return 3;

      // Invalid Status
      return -1;
    },

    getStatus(league) {
      const statusList = [
        {
          text: "Inscrições abertas",
          color: "#27AE60",
          filled: true,
          date: moment(league.enrollmentsFinalDate),
          label: "Inscrições Até:",
        },
        {
          text: "Em andamento",
          color: "#F2C94C",
          date: league.tournamentFinalDate,
          label: "Termina em:",
        },
        {
          text: "Em Breve",
          color: "#BDBDBD",
          date: league.enrollmentsInitDate,
          label: "Começa em:",
        },
        {
          text: "Finalizadas",
          color: "#BDBDBD",
          date: league.tournamentFinalDate,
          label: "Finalizado em:",
        },
      ];

      const statusValue = this.getStatusValue(league);
      return statusList[statusValue];
    },
  },
};
</script>

<style lang="scss" scoped>
.truncate {
  max-width: 95px;
  white-space: nowrap;
  overflow: hidden;
}
</style>
