var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0"},[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"secondary rounded-lg mt-8",staticStyle:{"height":"24px","width":"8px"}}),_c('v-card',{staticClass:"py-3 py-md-6 px-4 px-md-8 mb-4",attrs:{"color":"surface","width":"100%","flat":"","tile":""}},[_c('div',{staticClass:"d-flex flex-column flex-md-row align-md-center justify-space-between mb-6"},[_c('div',{staticClass:"d-flex align-center mb-2 mb-md-0"},[_c('back-button',{staticClass:"mr-2"}),_c('div',{staticClass:"text-h5 font-weight-bold flex-grow-1"},[_c('span',{staticClass:"saira"},[_vm._v(" Minhas Ligas ")])])],1),_c('v-btn',{staticClass:"rounded-l-0 rounded-r-lg",attrs:{"color":"primary","outlined":"","to":"/leagues"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple")]),_vm._v(" Todas as ligas ")],1)],1),_c('GamesChips',{attrs:{"disabled":_vm.loading}}),(_vm.loading)?_c('loader-content'):_c('section',[(_vm.leagues.length > 0)?_c('v-data-table',{staticClass:"transparent",attrs:{"headers":_vm.headers,"items":_vm.leagues,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":"","disable-sort":""},on:{"dblclick:row":($event, { item }) => _vm.handleSelectLeague(item)},scopedSlots:_vm._u([{key:`item.league`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pointer mr-3",attrs:{"size":"32","rounded":""}},[_c('v-img',{attrs:{"src":item.image ||
                      require('@/assets/leagues/default-league-logo.svg'),"contain":""},on:{"click":function($event){return _vm.handleSelectLeague(item)}}})],1),_c('span',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"text--body-4 primary--text pointer text-decoration-underline",domProps:{"textContent":_vm._s(item.name)},on:{"click":function($event){return _vm.handleSelectLeague(item)}}})],1)]}},{key:`item.team`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"150","close-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c('v-avatar',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pointer mr-3",attrs:{"size":"32","rounded":""},on:{"click":function($event){return _vm.handleSelectTeam(item)}}},[_c('v-img',{attrs:{"src":item.myTeam.logo,"contain":""}})],1),_c('span',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"pointer text-decoration-underline text--body-4",domProps:{"textContent":_vm._s(item.myTeam.name)},on:{"click":function($event){return _vm.handleSelectTeam(item)}}})],1)]}},{key:"default",fn:function(){return [_c('div',{staticClass:"d-flex flex-column justify-center align-center"},_vm._l((item.myTeam.team_players),function(player,index){return _c('div',{key:index,staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"x-small":""},domProps:{"textContent":_vm._s(
                          item.myTeam.userId === player.user.id
                            ? 'mdi-shield-account'
                            : 'mdi-account'
                        )}}),_c('span',{staticClass:"text--body-4 ms-1",domProps:{"textContent":_vm._s(player.user.nickname)}})],1)}),0)]},proxy:true}],null,true)})]}},{key:`item.game`,fn:function({ item }){return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"32","rounded":""},on:{"dblclick":function($event){$event.stopPropagation();}}},[_c('v-img',{attrs:{"src":item.game.thumb,"contain":""}})],1),_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(item.game.name)},on:{"dblclick":function($event){$event.stopPropagation();}}})],1)]}},{key:`item.enrollmentsDates`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(
                    _vm.formatDate(item.enrollmentsInitDate, 'DD/MM/YY') + ' à'
                  )},on:{"dblclick":function($event){$event.stopPropagation();}}}),_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(_vm.formatDate(item.enrollmentsFinalDate, 'DD/MM/YY'))},on:{"dblclick":function($event){$event.stopPropagation();}}})])]}},{key:`item.tournamentDates`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(
                    _vm.formatDate(item.tournamentInitDate, 'DD/MM/YY') + ' à'
                  )},on:{"dblclick":function($event){$event.stopPropagation();}}}),_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(_vm.formatDate(item.tournamentFinalDate, 'DD/MM/YY'))},on:{"dblclick":function($event){$event.stopPropagation();}}})])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column align-start"},[_c('div',{staticClass:"d-flex px-1 mt-1",style:({
                    border: `1px solid ${item.status.color}`,
                    backgroundColor: item.status.filled && item.status.color,
                    borderRadius: '4px',
                  }),on:{"dblclick":function($event){$event.stopPropagation();}}},[_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(item.status.text)},on:{"dblclick":function($event){$event.stopPropagation();}}})]),_c('div',{staticClass:"d-flex",on:{"dblclick":function($event){$event.stopPropagation();}}},[_c('v-icon',{attrs:{"x-small":""},domProps:{"textContent":_vm._s('mdi-calendar')}}),_c('span',{staticClass:"text--body-4 font-weight-medium ms-1",domProps:{"textContent":_vm._s(item.status.label)}})],1),_c('span',{staticClass:"text--body-4",domProps:{"textContent":_vm._s(_vm.formatDate(item.status.date, 'DD/MM/YY HH:mm'))},on:{"dblclick":function($event){$event.stopPropagation();}}})])]}}],null,true)}):_c('div',{staticClass:"d-flex flex-column align-center justify-center py-8"},[_c('v-icon',{attrs:{"x-large":""},domProps:{"textContent":_vm._s('mdi-emoticon-sad-outline')}}),_c('span',{staticClass:"text--body-2",domProps:{"textContent":_vm._s('Você não participou de nenhuma liga até o momento.')}}),(this.$route.name !== 'Leagues')?_c('PrimaryButton',{staticClass:"mt-6",attrs:{"icon":'mdi-emoticon-outline',"text":"Participar"},on:{"click":_vm.handleParticipate}}):_vm._e()],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }